import React, { useState, useEffect } from 'react'
import HotColdDrinks from '../menu/HotColdDrinks'
import Smoothies from '../menu/Smoothies'
import Breakfast from '../menu/Breakfast'
import Beverages from '../menu/Beverages'
import SideOrder from '../menu/SideOrder'
import PastryMore from '../menu/PastryMore'
import { Link } from 'react-router-dom'
import { ReactComponent as IconHouse } from '../resources/icons/house.svg'
import { ReactComponent as IconFace } from '../resources/icons/facebook.svg'
import { ReactComponent as IconCart } from '../resources/icons/cart.svg'
import Lunch from '../menu/Lunch'
import { getStrapiMenu } from '../strapiData/getStrapiMenu'

const Menu = ({ changeTheme, theme }) => {
  const [state, setState] = useState('Breakfast all day')
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'menu-containers?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiMenu(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  const handleTheme = () => {
    changeTheme()
  }

  const handleOptionChange = option => {
    setState(option)
  }

  const CardWithIcon = ({ title }) => (
    <button
      onClick={() => handleOptionChange(title)}
      className={state !== title ? 'active:text-amber-500 capitalize transition delay-150 duration-300 ease-in-out sm:md:text-lg text-sm mx-auto text-zinc-800 dark:text-zinc-300 font-medium sm:md:py-3 py-1 sm:md:px-2 px-1 hover:text-amber-500 cursor-pointer' : 'text-amber-500 capitalize transition delay-150 duration-300 ease-in-out sm:md:text-lg text-sm mx-auto font-medium sm:md:py-3 py-1 sm:md:px-2 px-1 hover:text-amber-500 cursor-pointer'}
    >
      {title}
    </button>
  )

  const LinkSection = ({ title }) => {
    return (
      <button
        onClick={() => handleOptionChange(title)}
        className={state !== title ? 'sm:md:text-base text-xs mx-2 leading-6 text-gray-500 hover:text-gray-900' : 'text-amber-500 capitalize transition delay-150 duration-300 ease-in-out sm:md:text-lg text-sm mx-auto font-medium sm:md:py-3 py-1 sm:md:px-2 px-1 hover:text-amber-500 cursor-pointer'}
      >
        {title}
      </button>
    )
  }

  return (
    <div className='bg-neutral-200/50 dark:bg-slate-800'>
      {strapiContent?.map(({ background, servicesList, index }) => (
        <div key={index + 1}>
          <div className='w-full mx-auto mt-0 bg-white dark:bg-slate-800 text-center shadow-neutral-500 dark:shadow-slate-900 shadow-lg sticky top-0 z-50 mb-0'>
            <div className='flex sm:md:items-center sm:md:flex-nowrap flex-wrap py-2 px-5'>
              {
                servicesList.map((value, index) => (
                  <CardWithIcon key={index} title={value.title} />
                ))
              }
              <a
                className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5'
                href='https://us.orderspoon.com/telluridecoffeecompany'
              >
                <IconCart className='w-5 h-5' />
              </a>
              <button type='button' onClick={() => handleTheme()} className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5'>
                {
                  theme ? (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'> </path></svg>) : (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z' fillRule='evenodd' clipRule='evenodd'> </path></svg>)
                }
              </button>
              <Link
                to='/'
                className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5'
              >
                <IconHouse className='w-5 h-5' />
              </Link>
            </div>
          </div>
          <div className='sm:md:min-h-screen min-h-full w-full mt-0 bg-neutral-200/50 dark:bg-slate-800'>
            <div className='bg-cover h-full sm:md:min-h-screen w-full' style={{ backgroundImage: `url(${background}` }}>
              <div className='sm:md:mx-auto my-2 w-full h-full sm:md:min-h-screen bg-gradient-to-tr from-white/20 dark:from-slate-900/30 '>
                {state === 'Breakfast all day' && <Breakfast />}
                {state === 'Lunch' && <Lunch />}
                {state === 'Side Order' && <SideOrder />}
                {state === 'Smoothies' && <Smoothies />}
                {state === 'Coffee & hot drinks' && <HotColdDrinks />}
                {state === 'Beverages' && <Beverages />}
                {state === 'Pastry & more' && <PastryMore />}
              </div>
            </div>
          </div>
          <footer className='text-center dark:bg-slate-800 dark:text-white bg-white text-slate-900'>
            <div className='max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8'>
              <nav className='flex flex-wrap justify-center -mx-5 -my-2'>
                <div className='px-5 py-2'>
                  {
                    servicesList.map((value, index) => (
                      <LinkSection key={index} title={value.title} icon={value.icon} />
                    ))
                  }
                </div>
              </nav>

              <div className='flex justify-center mt-8 space-x-6'>
                <button type='button' onClick={() => handleTheme()} className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5'>
                  {
                    theme ? (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'> </path></svg>) : (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z' fillRule='evenodd' clipRule='evenodd'> </path></svg>)
                  }
                </button>
                <Link
                  to='/'
                  className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5'
                >
                  <IconHouse className='w-5 h-5' />
                </Link>
                <a
                  href='https://www.facebook.com/telluridecoffeecompany'
                  className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5'
                >
                  <IconFace className='w-5 h-5' />
                </a>
                <a
                  className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5'
                  href='https://us.orderspoon.com/telluridecoffeecompany'
                >
                  <IconCart className='w-5 h-5' />
                </a>
              </div>

              <p className='mt-8 text-base leading-6 text-center text-gray-400'>
                © {new Date().getFullYear()} Telluride Digital News. All rights reserved.
              </p>
            </div>
          </footer>
        </div>
      ))}
    </div>
  )
}

export default Menu
