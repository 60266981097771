import React from 'react'
import bg from '../resources/bg/Home.jpeg'
import FormContact from '../components/FormContact'
import { Link } from 'react-router-dom'

import { ReactComponent as IconFace } from '../resources/icons/facebook.svg'
import { ReactComponent as IconInsta } from '../resources/icons/instagram.svg'

export default class Contact extends React.Component {
  render () {
    return (
      <div className='w-full h-screen min-h-screen'>
        <div className='w-full h-full grid grid-cols-2'>
          <div className='bg-black/20 dark:bg-black/60 sm:md:block hidden bg-cover bg-center backdrop-brightness-25' style={{ backgroundImage: `url(${bg})`, height: '100vh' }}>
            <br />
          </div>
          <div className='dark:bg-neutral-900 sm:md:col-span-1 col-span-2 w-full flex items-center justify-center sm:md:bg-neutral-200/50 bg-neutral-50 '>
            <div className='grid justify-items-center sm:md:w-full w-3/4'>
              <div className='grid grid-col-1 sm:md:grid-cols-1 justify-items-center'>
                <h1 className='text-2xl font-semibold my-2'>Contact Us</h1>
                <a href='mailto:telluridecoffeecompany@yahoo.com' className='inline-block text-center'>
                  telluridecoffeecompany@yahoo.com
                </a>
                <a href='tel:9703694400' className='inline-block text-center my-2'>
                  (970)-369-4400
                </a>
                <br />
                <address className='text-gray-600 font-medium'>
                  🇺🇸 565 Mountain Village Blvd, Telluride, CO, United States, 81435
                </address>
              </div>
              <FormContact
                to='telluridecoffeecompany@yahoo.com'
              />
              <div className='flex flex-grow gap-8 py-4'>
                <a className='text-rose-600 dark:text-white h-7 w-7' href='https://www.facebook.com/telluridecoffeecompany'><IconInsta /></a>
                <a className='text-blue-600 dark:text-white h-7 w-7' href='https://www.facebook.com/telluridecoffeecompany'><IconFace /></a>
              </div>
              <Link className='mt-2 px-2.5 py-1.5 bg-zinc-800 dark:bg-zinc-200 rounded-md shadow-slate-900/50 shadow-md text-white dark:text-black' to='/'>
                Back
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
