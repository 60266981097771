import React, { useState, useEffect } from 'react'
import { CardStep } from './components/Cards'
import { getStrapiMeals } from '../strapiData/getStrapiMeals'

const Breakfast = () => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'breakfast-menus'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiMeals(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  return (
    <div className='mt-0'>
      {strapiContent?.map(({ id, title, subtitle, cardMeals }, index) => (
        <div key={id}>
          <div className='p-2 grid justify-items-center bg-white dark:bg-slate-800 mb-5 mt-0'>
            <p className='text-red-400 dark:text-red-600/80 font-medium uppercase sm:md:text-5xl text-2xl'>{title}</p>
            <p className='text-gray-600/90 dark:text-gray-200/80 font-normal uppercase sm:md:text-lg text-md'>{subtitle}</p>
          </div>
          <div key={id + index} className='container mx-auto'>
            <div key={index} className='grid grid-cols-1 sm:md:mx-0 mx-5 gap-4 text-center justify-items-center py-2.5'>
              {cardMeals?.map(({ id, title, description, subDescription, meals }) => (
                <CardStep
                  key={id + title}
                  name={title}
                  description={description}
                  title={subDescription}
                  items={meals}
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Breakfast
