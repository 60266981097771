/* eslint-disable */
const token = process.env.REACT_APP_TOKEN
const STRAPI_URL = 'https://telluride-coffee-company-strapi.telluridedigitalworks.com/strapi'

export const getStrapiHero = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        background: STRAPI_URL + attributes.home_bg.data.attributes.url,
        cardContent: attributes.info_card.card
      }
    }
  )

  return values
}
/* eslint-enable */
