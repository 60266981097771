/* eslint-disable */
const token = process.env.REACT_APP_TOKEN
const STRAPI_URL = 'https://telluride-coffee-company-strapi.telluridedigitalworks.com/strapi'

export const getStrapiMeals = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}?populate[card_meals][populate]=*`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        title: attributes?.title,
        subtitle: attributes?.subtitle,
        smoothieSteps: attributes?.smoothie_steps,
        cardMeals: attributes?.card_meals?.data.map(({attributes}) => ({
          id: id,
          title: attributes?.title,
          description: attributes?.description,
          subDescription: attributes?.subDescription,
          meals: attributes?.meals?.data.map(({ attributes }) => ({
            sortInMenu: attributes?.sort_in_menu,
            name: attributes?.meal_title,
            description: attributes?.meal_description,
            cost: attributes?.cost,
            secondCost: attributes?.second_cost,
            thirdCost: attributes?.third_cost
          })).sort((a, b) => a.sortInMenu - b.sortInMenu)
        }))
      }
    }
  )

  return values
}
/* eslint-enable */
