import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as IconFace } from '../resources/icons/facebook.svg'
import ButtonToggleMode from '../components/ButtonToggleMode'
import { getStrapiHero } from '../strapiData/getStrapiHome'

const Home = ({ changeTheme, theme }) => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'homes?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiHero(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  const classBtn = 'm-1 px-2 py-1 sm:md:px-4 sm:md:py-2 font-semibold bg-amber-100 dark:bg-slate-800 text-slate-800 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg sm:md:text-base text-xs text-center flex items-center shadow-sm shadow-neutral-500/50'

  return (
    <div className='w-full bg-cover bg-center h-screen min-h-screen'>
      {strapiContent?.map(({ background, cardContent, index }) => (

        <div key={index} className='w-full h-screen min-h-screen bg-cover bg-center bg-black/20 dark:bg-black/60' style={{ backgroundImage: `url(${background})`, height: '32rem' }}>
          <div className='bg-black/20 dark:bg-black/60 dark:backdrop-brightness-75 w-full h-[5vh] flex items-end justify-end'>
            <ButtonToggleMode theme={theme} changeTheme={changeTheme} />
          </div>
          <div className='bg-black/20 dark:bg-black/60 dark:backdrop-brightness-75 w-full h-[15vh] flex items-end justify-center '>
            <div className='my-2 w-1/2 flex flex-col items-center justify-center'>
              <a
                className='text-center sm:md:p-5 p-2 rounded-xl font-normal sm:md:text-md text-sm bg-white dark:bg-slate-800 text-slate-800 dark:text-gray-200'
                href='https://www.facebook.com/telluridecoffeecompany'
              >
                Follow Us
              </a>
              <a href='https://www.facebook.com/telluridecoffeecompany' className={classBtn}>
                <IconFace className='text-blue-400 dark:text-blue-600 h-5 w-5' />
              </a>
            </div>
          </div>
          <div className='bg-black/20 dark:bg-black/60 dark:backdrop-brightness-75 w-full h-[80vh] flex items-center justify-center '>
            <div className='mx-auto container p-10'>
              <div className='grid justify-items-center'>
                <div className='my-2 w-1/2 flex items-center justify-center flex-col md:flex-row'>
                  <Link
                    to='menu'
                    className={`${classBtn} w-[7rem] py-2 justify-center flex`}
                    href='/menu'
                  >
                    Menu
                  </Link>
                  <a
                    className={`${classBtn} w-[7rem] md:w-[9rem] py-2 justify-center flex`}
                    href='https://us.orderspoon.com/telluridecoffeecompany'
                  >
                    Order Online
                  </a>
                  <Link to='/contact' className={`${classBtn} w-[7rem] py-2 justify-center flex`}>
                    Contact
                  </Link>
                </div>
                <div className='my-2 w-1/2 flex items-between justify-center'>
                  <div className='text-center sm:md:p-5 p-2 rounded-xl font-light sm:md:text-md text-xs bg-white dark:bg-slate-800 text-slate-800 dark:text-gray-200'>
                    <div className='text-center flex flex-col justify-center items-center gap-4'>
                      {cardContent?.map(({ label, index }) => (
                        <div key={index} className='md:w-[65%]'>
                          <p>{label}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Home
