import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './routes/Home'
import Contact from './routes/Contact'
import Menu from './routes/Menu'
import Maintenance from './routes/Maintenance'

const App = () => {
  const [darkTheme, setDarkTheme] = useState(true)

  const changeTheme = () => {
    setDarkTheme(!darkTheme)
  }

  switch (darkTheme) {
    case true:
      document.documentElement.classList.add('light')
      document.documentElement.classList.remove('dark')
      break
    case false:
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
      break
    default:
      document.documentElement.classList.add('light')
      break
  }

  return (
    <Routes>
      <Route path='/' element={<Home changeTheme={changeTheme} theme={darkTheme} />} />
      <Route path='/about' element={<Maintenance />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/menu' element={<Menu changeTheme={changeTheme} theme={darkTheme} />} />
    </Routes>
  )
}

export default App
