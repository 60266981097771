import React, { useState, useEffect } from 'react'
import { CardStep } from './components/Cards'
import { getStrapiMeals } from '../strapiData/getStrapiMeals'

const PastryMore = () => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'pastry-menus'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiMeals(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  return (
    <div>
      {strapiContent?.map(({ id, title, cardMeals }) => (
        <div key={id + title}>
          <div key={id + title} className='p-2 grid justify-items-center bg-white dark:bg-slate-800 mb-5 mt-0'>
            <p className='text-red-400 dark:text-red-600/80 font-medium uppercase sm:md:text-5xl text-2xl'>
              {title}
            </p>
          </div>
          <div key={id} className='grid grid-cols-1 sm:md:mx-0 mx-5 gap-4 text-center justify-items-center py-2.5'>
            {cardMeals?.map(({ id, title, meals }, index) => (
              <CardStep
                key={`${id}-${title}-${index}`}
                name={title}
                items={meals}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default PastryMore
