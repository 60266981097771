/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { CardStep } from './components/Cards'
import { getStrapiMeals } from '../strapiData/getStrapiMeals'

const Smoothies = () => {
  const [strapiContent, setStrapiContent] = useState([])
  const [isStep, setIsStep] = useState("stepOne")
  const collectionData = 'smoothies-menus'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiMeals(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  const handleStep = newStep => {
    setIsStep(newStep)
  }

  const darkModeBtnActive = 'text-slate-100/70 bg-red-400 hover:bg-red-400/80 focus:ring-4 focus:outline-none focus:ring-red-400/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center focus:ring-red-400/20 mr-2 mb-2 dark:bg-red-800 dark:text-white'

  const darkModeBtn = 'text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/80 mr-2 mb-2'

  return (
    <div>
      {strapiContent?.map(({ id, title, subtitle, smoothieSteps, cardMeals }) => (
        <div key={id}>
          <div className='p-2 grid justify-items-center bg-white dark:bg-slate-800 mb-5 mt-0'>
            <p className='text-red-400 dark:text-red-600/80 font-medium uppercase sm:md:text-5xl text-2xl'>
              {title}
            </p>
            <p className='text-gray-600/90 dark:text-gray-200/80 font-normal uppercase sm:md:text-lg text-md'>
              {subtitle}
            </p>
          </div>
          <div className='container mx-auto'>
            <div className='flex justify-center items-center'>
              <button type='button' onClick={() => handleStep("stepOne")} className={isStep === "stepOne" ? darkModeBtnActive : darkModeBtn}>
                Step 1
              </button>
              <button type='button' onClick={() => handleStep("stepTwo")} className={isStep === "stepTwo" ? darkModeBtnActive : darkModeBtn} key={`${id}-stepTwo`}>
                Step 2
              </button>
              <button type='button' onClick={() => handleStep("stepThree")} className={isStep === "stepThree" ? darkModeBtnActive : darkModeBtn} key={`${id}-stepThree`}>
                Step 3
              </button>
              <button type='button' onClick={() => handleStep("stepFour")} className={isStep === "stepFour" ? darkModeBtnActive : darkModeBtn} key={`${id}-stepFour`}>
                Add Ons
              </button>
            </div>
            <div key={`${id}-content`} className='grid grid-cols-1 sm:md:mx-0 mx-5 gap-4 text-center justify-items-center py-2.5'>
              {isStep === "stepOne" && (
                <CardStep
                  title='step 1'
                  subtitle='Pick 2 Fruits'
                  items={smoothieSteps.stepOne}
                />
              )}
              {isStep === "stepTwo" && (
                <CardStep
                  title='Step 2'
                  subtitle='Pick a Base'
                  items={smoothieSteps.stepTwo}
                />
              )}
              {isStep === "stepThree" && (
                <CardStep
                  title='Step 3'
                  subtitle='Add Something Green'
                  items={smoothieSteps.stepThree}
                />
              )}
              {isStep === "stepFour" && (
                <CardStep
                  title='Add ons'
                  subtitle='Add Ons'
                  items={smoothieSteps.stepFour}
                />
              )}
              {cardMeals?.map(({ title, meals }, index) => (
                <CardStep
                  key={`${id}-${title}-${index}`}
                  name={title}
                  items={meals}
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Smoothies
/* eslint-enable */
