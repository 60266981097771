import React, { useRef, useState } from 'react'

const FormContact = ({ to = 'sonnymijael@gmail.com' }) => {
  const [statusEmail, setStatusEmail] = useState('waiting') // waiting, sending, send or error
  const form = useRef()

  const sendEmail = (e) => {
    // change status email to send email
    setStatusEmail('sending')
    e.preventDefault()

    const formData = new FormData()
    formData.append('to', to) // destination address for email
    formData.append('name', form.current.name.value)
    formData.append('surname', form.current.surname.value)
    formData.append('email', form.current.email.value)
    formData.append('phone', form.current.phone.value)
    formData.append('message', form.current.message.value)

    fetch('https://api.telluridedigitalnews.com/telluride/email/send', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (data.status) {
          // send email success
          setTimeout(() => setStatusEmail('send'), 2000)
        } else {
          // send email error
          setTimeout(() => setStatusEmail('error'), 2000)
        }
      })

    console.log(statusEmail)
  }

  const ButtonSend = () => {
    // this component set status email when send email
    switch (statusEmail) {
      case 'sending':
        return (
          <button disabled type='button' className='text-white bg-[#24292F] hover:bg-[#24292F] focus:ring-4 focus:outline-none focus:ring-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-[#24292F] inline-flex items-center'>
            <svg aria-hidden='true' role='status' className='inline mr-3 w-4 h-4 text-white animate-spin' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z' fill='#E5E7EB' />
              <path d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z' fill='currentColor' />
            </svg>
            Sending...
          </button>
        )
      case 'send':
        return (
          <button type='submit' className='text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-neutral-500 dark:hover:bg-[#050708]/30 mr-2 mb-2'>
            <svg className='mr-2 -ml-1 w-4 h-4' fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M207.8 20.73c-93.45 18.32-168.7 93.66-187 187.1c-27.64 140.9 68.65 266.2 199.1 285.1c19.01 2.888 36.17-12.26 36.17-31.49l.0001-.6631c0-15.74-11.44-28.88-26.84-31.24c-84.35-12.98-149.2-86.13-149.2-174.2c0-102.9 88.61-185.5 193.4-175.4c91.54 8.869 158.6 91.25 158.6 183.2l0 16.16c0 22.09-17.94 40.05-40 40.05s-40.01-17.96-40.01-40.05v-120.1c0-8.847-7.161-16.02-16.01-16.02l-31.98 .0036c-7.299 0-13.2 4.992-15.12 11.68c-24.85-12.15-54.24-16.38-86.06-5.106c-38.75 13.73-68.12 48.91-73.72 89.64c-9.483 69.01 43.81 128 110.9 128c26.44 0 50.43-9.544 69.59-24.88c24 31.3 65.23 48.69 109.4 37.49C465.2 369.3 496 324.1 495.1 277.2V256.3C495.1 107.1 361.2-9.332 207.8 20.73zM239.1 304.3c-26.47 0-48-21.56-48-48.05s21.53-48.05 48-48.05s48 21.56 48 48.05S266.5 304.3 239.1 304.3z' /> </svg>
            Send
          </button>
        )
      case 'error':
        return (
          <button disabled type='submit' className='text-white bg-red-500 hover:bg-red-500/90 focus:ring-4 focus:outline-none focus:ring-red-500/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-neutral-500 dark:hover:bg-[#050708]/30 mr-2 mb-2'>
            <svg className='mr-2 -ml-1 w-4 h-4' fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M207.8 20.73c-93.45 18.32-168.7 93.66-187 187.1c-27.64 140.9 68.65 266.2 199.1 285.1c19.01 2.888 36.17-12.26 36.17-31.49l.0001-.6631c0-15.74-11.44-28.88-26.84-31.24c-84.35-12.98-149.2-86.13-149.2-174.2c0-102.9 88.61-185.5 193.4-175.4c91.54 8.869 158.6 91.25 158.6 183.2l0 16.16c0 22.09-17.94 40.05-40 40.05s-40.01-17.96-40.01-40.05v-120.1c0-8.847-7.161-16.02-16.01-16.02l-31.98 .0036c-7.299 0-13.2 4.992-15.12 11.68c-24.85-12.15-54.24-16.38-86.06-5.106c-38.75 13.73-68.12 48.91-73.72 89.64c-9.483 69.01 43.81 128 110.9 128c26.44 0 50.43-9.544 69.59-24.88c24 31.3 65.23 48.69 109.4 37.49C465.2 369.3 496 324.1 495.1 277.2V256.3C495.1 107.1 361.2-9.332 207.8 20.73zM239.1 304.3c-26.47 0-48-21.56-48-48.05s21.53-48.05 48-48.05s48 21.56 48 48.05S266.5 304.3 239.1 304.3z' /> </svg>
            Error !.
          </button>
        )
      default:
        return (
          <button type='submit' className='text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-neutral-500 dark:hover:bg-[#050708]/30 mr-2 mb-2'>
            <svg className='mr-2 -ml-1 w-4 h-4' fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M207.8 20.73c-93.45 18.32-168.7 93.66-187 187.1c-27.64 140.9 68.65 266.2 199.1 285.1c19.01 2.888 36.17-12.26 36.17-31.49l.0001-.6631c0-15.74-11.44-28.88-26.84-31.24c-84.35-12.98-149.2-86.13-149.2-174.2c0-102.9 88.61-185.5 193.4-175.4c91.54 8.869 158.6 91.25 158.6 183.2l0 16.16c0 22.09-17.94 40.05-40 40.05s-40.01-17.96-40.01-40.05v-120.1c0-8.847-7.161-16.02-16.01-16.02l-31.98 .0036c-7.299 0-13.2 4.992-15.12 11.68c-24.85-12.15-54.24-16.38-86.06-5.106c-38.75 13.73-68.12 48.91-73.72 89.64c-9.483 69.01 43.81 128 110.9 128c26.44 0 50.43-9.544 69.59-24.88c24 31.3 65.23 48.69 109.4 37.49C465.2 369.3 496 324.1 495.1 277.2V256.3C495.1 107.1 361.2-9.332 207.8 20.73zM239.1 304.3c-26.47 0-48-21.56-48-48.05s21.53-48.05 48-48.05s48 21.56 48 48.05S266.5 304.3 239.1 304.3z' /> </svg>
            Send
          </button>
        )
    }
  }

  return (
    <form className=' sm:md:w-3/4 w-full sm:md:m-5 m-10' ref={form} onSubmit={sendEmail}>
      {statusEmail === 'send' && (
        <div className='bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md' role='alert'>
          <div className='flex'>
            <div className='py-1'><svg className='fill-current h-6 w-6 text-teal-500 mr-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z' /></svg></div>
            <div>
              <p className='font-bold'>Notification of the system</p>
              <p className='text-sm'>The mail has been sent correctly</p>
            </div>
          </div>
        </div>
      )}
      <div className='grid gap-16 lg:grid-cols-2 p-5 bg-white dark:bg-neutral-600/50 rounded-xl'>
        <div>
          <label htmlFor='name' className='block mb-2 text-sm font-medium text-neutral-900 dark:text-neutral-300'>First name</label>
          <input type='text' id='name' className='bg-neutral-50 border border-neutral-300 text-neutral-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder='John' required />
        </div>
        <div>
          <label htmlFor='surname' className='block mb-2 text-sm font-medium text-neutral-900 dark:text-neutral-300'>Last name</label>
          <input type='text' id='surname' className='bg-neutral-50 border border-neutral-300 text-neutral-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder='Doe' required />
        </div>
        <div>
          <label htmlFor='email' className='block mb-2 text-sm font-medium text-neutral-900 dark:text-neutral-300'>Email</label>
          <input type='text' id='email' className='bg-neutral-50 border border-neutral-300 text-neutral-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder={to} required />
        </div>
        <div>
          <label htmlFor='phone' className='block mb-2 text-sm font-medium text-neutral-900 dark:text-neutral-300'>Phone number</label>
          <input type='tel' id='phone' className='bg-neutral-50 border border-neutral-300 text-neutral-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder='1234536782' required />
        </div>
        <div className='col-span-2'>
          <textarea id='message' className='bg-neutral-50 border border-neutral-300 text-neutral-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' name='message' cols='30' rows='10'> </textarea>
        </div>
        <div className='col-span-2 grid justify-items-center'>
          <ButtonSend />
        </div>
      </div>
    </form>
  )
}

export default FormContact
