import CardMenuMin from '../../components/CardMenuMin'

const CardMinStep = ({ title, size = null }) => (
  <div className='flex mb-2 w-full justify-center rounded-xl shadow-red-500 dark:shadow-red-800 shadow-xs'>
    <div className={size === null ? 'w-full p-2 text-center bg-slate-100/80 dark:bg-slate-600/80 rounded-xl' : 'w-3/4 p-2 text-center bg-slate-100/80 flex items-center justify-center dark:bg-slate-200/80 rounded-l-xl'}>
      <p className='text-lg font-medium text-red-600 dark:text-red-600'>
        {title}
      </p>
    </div>
    {size !== null && (
      <div className='w-1/4 p-2 text-center flex items-center justify-center bg-blue-900/70 dark:bg-blue-800 rounded-r-xl'>
        <p className='text-md font-light text-neutral-100'>
          ${size}
        </p>
      </div>
    )}
  </div>
)

const CardMultStep = ({ name, title, cost1, cost2, cost3 }) => (
  <div className='flex w-full justify-center rounded-xl shadow-red-500 dark:shadow-red-800 shadow-xs'>
    <div className='w-3/4 p-2 flexc text-center bg-slate-100/80 flex items-center justify-center dark:bg-slate-200/80 rounded-l-xl'>
      <p className='text-lg font-medium text-red-600 dark:text-red-600'>
        {title}
      </p>
    </div>
    <div className='w-3/4 p-2 text-center flex items-center justify-center flex-col bg-blue-900/70 dark:bg-blue-800 rounded-r-xl'>
      {cost1 && (
        <p className='text-md font-light text-neutral-100'>
          {name === 'ALTERNATIVE MILK' || name === 'EXTRAS' ? `$${cost1}` : `12 OZ - ${cost1}`}
        </p>
      )}
      {cost2 && (
        <p className='text-md font-light text-neutral-100'>
          16 OZ - ${cost2}
        </p>
      )}
      {cost3 && (
        <p className='text-md font-light text-neutral-100'>
          20 OZ - ${cost3}
        </p>
      )}
    </div>
  </div>
)

const CardStep = ({ name, description, title, items }) => (
  <div className='mx-auto max-w-5xl w-full bg-white dark:bg-slate-800 rounded-xl p-5 mt-0'>
    {name && <Heading1 title={name} text={description} />}
    {title && <h2 className='text-xl mb-2 font-light text-red-400 flex items-center justify-center'>{title}</h2>}
    <hr />
    <div className='grid sm:md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:md:mx-0 mx-5 gap-4 text-center justify-items-center py-2.5'>
      {items.map((item, id) => (
        !item.description ? <CardMinStep key={id} title={item.name} size={item.cost} /> : <CardMenuMin key={id} title={item.name} cost={item.cost} description={item.description} />
      ))}
    </div>
  </div>
)

const CardOptionCosts = ({ id, name, description, title, items }) => {
  const lastIndex = items.length - 1

  return (
    <div key={id + name} className='mx-auto max-w-5xl w-full bg-white dark:bg-slate-800 rounded-xl p-5 mt-0'>
      {name && <Heading1 title={name} text={description} />}
      {title && <h2 className='text-xl mb-2 font-light text-red-400 flex items-center justify-center'>{title}</h2>}
      <hr />
      <div key={id} className='flex flex-wrap justify-center md:justify-none sm:md:mx-0 md:mx-5 gap-2 text-center justify-items-center py-2.5'>
        {items.map((item, id) => {
          const isLast = id === lastIndex
          return !item.cost && !item.secondCost && !item.thirdCost
            ? <JustText key={id} title={item.name} isLast={isLast} />
            : <div key={id} className='w-full md:w-[30%] flex lg:w-[23%]'><CardMultStep name={name} key={id} title={item.name} cost1={item.cost} cost2={item.secondCost} cost3={item.thirdCost} /></div>
        })}
      </div>
    </div>
  )
}

const Heading1 = ({ title, text = null }) => (
  <div className='grid grid-cols-1 sm:md:mx-0 md:mx-5 gap-4 text-center justify-items-center py-2.5 w-full mb-0'>
    <div className='p-2 grid justify-items-center bg-white dark:bg-transparent mb-1 rounded-xl w-full'>
      <p className='text-red-400 dark:text-red-600 font-medium uppercase sm:md:text-3xl text-xl'>
        {title}
      </p>
      <p className='text-gray-600/90 dark:text-gray-200/80 font-normal uppercase sm:md:text-md text-sm whitespace-pre-line'>
        {text !== null && text}
      </p>
    </div>
  </div>
)

const CardMinimal = ({ title, size }) => (
  <div className='flex mb-2 w-full justify-center rounded-xl shadow-red-500 dark:shadow-red-800 shadow-xs'>
    <div className='w-3/4 p-2 text-center bg-white dark:bg-slate-200/80 rounded-l-xl'>
      <p className='text-lg font-medium text-red-400 dark:text-red-600'>
        {title}
      </p>
    </div>
    <div className='w-1/4 p-2 text-center bg-red-400 dark:bg-red-800 rounded-r-xl'>
      <p className='text-md font-light text-white'>
        ${size}
      </p>
    </div>
  </div>
)

const JustText = ({ title, isLast }) => (
  <p className='text-gray-600/90 dark:text-gray-400 font-normal uppercase sm:md:text-md text-sm'>
    {title} {isLast ? '' : '-'}
  </p>
)

export { Heading1, CardStep, CardMinStep, CardMinimal, CardOptionCosts }
