import React from 'react'

export default class CardMenuMin extends React.Component {
  state = {
    btnShow: false
  }

  setIsOpen = () => {
    this.setState({ btnShow: !this.state.btnShow })
  }

  NameElement = ({ name }) => (
    <p className='text-red-600 dark:text-red-200 text-center font-semibold sm:md:text-3xl text-xl'>
      {name}
    </p>
  )

  SubtitleElement = ({ subtitle }) => {
    subtitle.length > 0 && <p className='text-xs text-neutral-600 dark:text-neutral-400'>{subtitle}</p>
  }

  CostElement = ({ cost }) => (
    <span className='sm:md:text-sm text-xs dark:text-gray-800/80 text-gray-200 dark:bg-blue-200 bg-blue-900/70 rounded-xl p-2 m-1'>
      ${cost}
    </span>
  )

  SectionElement = ({ section }) => (
    <span className='sm:md:text-sm text-xs dark:text-gray-600 text-gray-200 dark:bg-blue-200 bg-blue-900/70 rounded-xl p-1 m-1'>
      #{section}
    </span>
  )

  DescriptionElement = ({ description }) => (
    <p className='text-gray-600 dark:text-neutral-200 sm:md:text-xs text-sm whitespace-pre-line capitalize'>
      {description}
    </p>
  )

  BtnOpenDescription = () => (
    <span onClick={() => this.setIsOpen()} className='text-center cursor-pointer md:text-sm text-xs dark:text-gray-800/80 text-gray-200 dark:bg-blue-200 bg-blue-900/70 rounded-md p-2 m-1'>
      {!this.state.btnShow ? 'ingredients' : 'Back ⤴ '}
    </span>
  )

  render () {
    const { title, cost = null, description } = this.props

    return (
      <div className='my-3 max-w-sm w-full rounded-lg bg-white ring-slate-400/5 dark:bg-slate-800/80 ring-1 dark:ring-slate-900/5 shadow-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-90 duration-300'>
        <div className='flex flex-col items-center sm:md:p-10 p-5'>
          {!this.state.btnShow && (
            <>
              <this.NameElement name={title} />
              <div className='flex flex-row sm:md:flex-row items-center'>
                {description === ' ' ? <></> : description.length > 20 ? <this.BtnOpenDescription /> : <p className='font-light p-2 bg-blue-900/70 text-sm text-white rounded-md dark:text-gray-200'>{description}</p>}
                {cost === null ? null : <this.CostElement cost={cost} />}
              </div>
            </>
          )}
          {this.state.btnShow && (
            <>
              <this.DescriptionElement description={description} />
              <this.BtnOpenDescription />
            </>
          )}
        </div>
      </div>
    )
  }
}
