import React, { useState, useEffect } from 'react'
import { CardStep } from './components/Cards'
import { getStrapiMeals } from '../strapiData/getStrapiMeals'

const Beverages = () => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'beverage-menus'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiMeals(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  return (
    <>
      {strapiContent?.map(({ id, title, subtitle, cardMeals }, index) => (
        <div key={id + index}>
          <div className='p-2 grid justify-items-center bg-white dark:bg-slate-800 mb-5 mt-0'>
            <p className='text-red-400 dark:text-red-600/80 font-medium uppercase sm:md:text-5xl text-2xl'>
              {title}
            </p>
            <p className='text-gray-600/90 dark:text-gray-200/80 font-normal uppercase sm:md:text-lg text-md'>
              {subtitle}
            </p>
          </div>
          <div className='container mx-auto'>
            <div className='grid grid-cols-1 sm:md:mx-0 mx-5 gap-4 text-center justify-items-center py-2.5'>
              {cardMeals?.map(({ title, description, subDescription, meals, id }) => (
                <CardStep
                  key={id + title}
                  name={title}
                  description={description}
                  title={subDescription}
                  items={meals}
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default Beverages
